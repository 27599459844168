import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../pages/custom/Home.vue'
import AboutView from '../views/AboutView.vue'
import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },
  
  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/export', name:'tools_export', title: 'Exporteren', component: () => import('../pages/custom/Tools/Export') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  { path: '/tools/test', name:'tools_test', title: 'Test', component: () => import('../pages/custom/Tools/Test') },
  

// CUSTOMERS
{ path: '/customers', sectionTitle: 'Klanten', icon:'fa-users', name:'customers', title:"Overzicht", component: () => import('../pages/custom/Customer/List') },
{ path: '/projects', sectionTitle: 'Projecten', icon:'fa-screwdriver-wrench', name:'projects', title:"Overzicht", component: () => import('../pages/custom/Project/List') },
{ path: '/projects/customer/:cus_id', name:'customerprojects', component: () => import('../pages/custom/Project/List') },
// { path: '/projects/quotations', name:'quotations', title:'Offertes', component: () => import('../pages/custom/Quotation/List') },
{ path: '/projects/quotations/:pro_id', name:'projectquotations', title:'Offertes', component: () => import('../pages/custom/Quotation/List') },
//{ path: '/projects/invoices/:cus_id', name:'customerinvoices', title:'Facturen', component: () => import('../pages/custom/Invoice/List') },
{ path: '/projects/invoices/:cus_id/:pro_id', name:'projectinvoices', title:'Facturen', component: () => import('../pages/custom/Invoice/List') },
{ path: '/projects/invoices/new/:cus_id', name:'newinvoice', _title:'Facturen', component: () => import('../pages/custom/Invoice/New') },

// MEDEWERKERS
{ path: '/users', sectionTitle:'Gebruikers', icon:'fa-person-chalkboard', title:'Overzicht',name: 'users_overview', component: () => import('../pages/custom/User/List') },
// { path: '/users/edit/:id', name: 'user_edit', component: () => import('../pages/base/User/Edit') },
// { path: '/users/new', name: 'user_add', component: () => import('../pages/base/User/Edit') },
{ path: '/users/details/:id', name: 'users_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Gebruiker details', controller:'users'} },
{ path: '/users/edit/:id', name: 'users_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker bewerken', controller:'users', afterSave:{all:'/users'}} },
{ path: '/users/new',  name: 'users_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker toevoegen', controller:'users', afterSave:{all:'/users'}} },

// roles
{ path: '/users/roles', sectionTitle:'Rollen', title:'Rollen', name: 'roles_overview', component: () => import('../pages/base/Role/List') },
{ path: '/roles/edit/:id', name: 'roles_edit', component: () => import('../pages/base/Role/Edit') },
{ path: '/roles/new', title:'Toevoegen', name: 'roles_add', component: () => import('../pages/base/Role/Edit') },
  
// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/mailtemplates', name:'settings_mailtemplates', title:"Mail templates", component: () => import('../pages/base/Settings/Mailtemplates') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/base/Settings/MSGraph') },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_COMPANY_NAME;
  next();
});

export default router
